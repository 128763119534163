// Generated by Framer (b2eeca0)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {Mf6r9YlEO: {hover: true}};

const cycleOrder = ["Mf6r9YlEO"];

const variantClassNames = {Mf6r9YlEO: "framer-v-1iaafau"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Mf6r9YlEO", image: Eu_Aav0YB = {src: new URL("assets/gkxo6e9beetH7LHHyCav3j8UcSw.png", import.meta.url).href}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Mf6r9YlEO", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-8SdNd", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-1iaafau", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Mf6r9YlEO"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"Mf6r9YlEO-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 96, intrinsicWidth: 358, pixelHeight: 96, pixelWidth: 358, sizes: "76px", ...toResponsiveImage(Eu_Aav0YB)}} className={"framer-1ckyvmu"} data-framer-name={"Company_Alt_Shift_Logotype_True_Color_White"} layoutDependency={layoutDependency} layoutId={"X7Y2CSgcX"} style={{opacity: 0.25}} transition={transition} variants={{"Mf6r9YlEO-hover": {opacity: 1}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-8SdNd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-8SdNd .framer-1jvgsay { display: block; }", ".framer-8SdNd .framer-1iaafau { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 65px; justify-content: center; overflow: hidden; padding: 24px 24px 24px 24px; position: relative; width: 124px; }", ".framer-8SdNd .framer-1ckyvmu { aspect-ratio: 3.7291666666666665 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 20px); overflow: visible; position: relative; width: 100%; }", ".framer-8SdNd .framer-v-1iaafau .framer-1iaafau { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-8SdNd .framer-1iaafau { gap: 0px; } .framer-8SdNd .framer-1iaafau > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-8SdNd .framer-1iaafau > :first-child { margin-top: 0px; } .framer-8SdNd .framer-1iaafau > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 65
 * @framerIntrinsicWidth 124
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"J40GtrEjD":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Eu_Aav0YB":"image"}
 */
const FramerrSyPq0cuh: React.ComponentType<Props> = withCSS(Component, css, "framer-8SdNd") as typeof Component;
export default FramerrSyPq0cuh;

FramerrSyPq0cuh.displayName = "client logo";

FramerrSyPq0cuh.defaultProps = {height: 65, width: 124};

addPropertyControls(FramerrSyPq0cuh, {Eu_Aav0YB: {__defaultAssetReference: "data:framer/asset-reference,gkxo6e9beetH7LHHyCav3j8UcSw.png?originalFilename=Company%3DAlt%2BShift%2C-Logotype%3DTrue%2C-Color%3DWhite.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerrSyPq0cuh, [])